import React from 'react';
import custom_palettes from 'theme/custom_palettes';
import moment from 'moment';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  TimeScale
} from 'chart.js';
import 'chartjs-adapter-moment';
import { Line } from 'react-chartjs-2';
import { myChartJsTooltipHandler } from 'utils';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  TimeScale
);

// @ts-ignore
Tooltip.positioners.custom = function (elements, eventPosition) {
  /** @type {Chart.Tooltip} */
  var tooltip = this;

  /* ... */

  return {
    x: eventPosition.x,
    y: eventPosition.y
  };
};

const externalTooltipHandler = (context: any) => {
  myChartJsTooltipHandler(context, (tooltip: any) => {
    const data = tooltip.dataPoints[0].raw.tooltip;
    const dataset = tooltip.dataPoints[0].dataset;
    const title = dataset.label.split('(');
    const body = `<div class="label">${data.label}</div>
    <div class="values" style="color: ${dataset.backgroundColor}">
      <div class="q">${title[0]}</div>
      <div class="a">${data.value} ${title[1].replace(')', '')}</div>
    </div>
    `;
    return body;
  });
};

// ===========================================================================
interface LineGraphProps {
  data: any[];
  xAxisLabel?: string;
  xAxisUnit?: string;
  yAxisLabel?: string;
  yAxisUnit?: string;
  style?: React.CSSProperties;
}

export default function LineGraph(props: LineGraphProps) {
  const options: any = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false
      },
      tooltip: {
        enabled: false,
        position: 'custom',
        external: externalTooltipHandler
      }
    },
    elements: {
      point: {
        radius: 3
      },
      line: {
        borderWidth: 2
      }
    },
    scales: {
      x: {
        type: 'time',
        time: {
          unit: 'day'
        },
        ticks: {
          callback: function (value: any, index: any, ticks: any) {
            return moment(value).format('D MMM YYYY');
          }
        },
        border: {
          color: custom_palettes.gray[800],
          width: 1
        },
        title: {
          display: true,
          text: `${props.xAxisLabel} (UTC+8:00)`,
          color: custom_palettes.blue[800],
          padding: 5,
          font: {
            size: 16
          }
        }
        // min: moment().subtract(7, 'days').valueOf(),
        // max: moment().valueOf()
      },
      y: {
        type: 'linear',
        display: true,
        position: 'left',
        grid: { display: false },
        border: {
          color: custom_palettes.blue[800],
          width: 3
        },
        title: {
          display: true,
          text: `${props.yAxisLabel} ${props.yAxisUnit}`,
          color: custom_palettes.blue[800],
          padding: 5,
          font: {
            size: 16
          }
        }
      }
    },
    parsing: {
      xAxisKey: 'x'
    }
  };

  const data: any = {
    datasets: [
      {
        yAxisID: 'y',
        label: `${props.yAxisLabel} ${props.yAxisUnit}`,
        data: props.data,
        borderColor: custom_palettes.blue[800],
        backgroundColor: custom_palettes.blue[800],
        pointStyle: 'circle',
        pointBackgroundColor: custom_palettes.white.main
      }
    ]
  };

  return (
    <div
      style={{
        position: 'relative' as const,
        ...props.style
      }}
    >
      <div
        style={{
          position: 'relative',
          width: '100%',
          height: 400
        }}
      >
        <Line options={options} data={data} />
      </div>
    </div>
  );
}
