import React, { useEffect, useState, useRef } from 'react';
import _ from 'lodash';
import { useAppSelector, useDebounce } from 'redux/hooks';
import custom_palettes from 'theme/custom_palettes';
import moment from 'moment';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  TimeScale,
  Decimation
} from 'chart.js';
import 'chartjs-adapter-moment';
import zoomPlugin from 'chartjs-plugin-zoom';
import { Line } from 'react-chartjs-2';
import { getZoomedData } from 'utils/data';
import { myChartJsTooltipHandler } from 'utils';
import momentTZ from 'moment-timezone';

var reduceDataPointsPlugin = {
  id: 'my_decimation',
  beforeUpdate: function (chart: any, args: any, options: any) {
    filterData(chart, args, options);
  }
};

function filterData(chart: any, args: any, options: any) {
  var maxRenderedPointsX = 100;
  var datasets = chart.data.datasets;
  if (!datasets.length) return;
  if (!chart.data.origDatasetsData || chart.data.origDatasetsData.length == 0) {
    chart.data.origDatasetsData = [];
    for (var i in datasets) {
      if (datasets[i].data && datasets[i].data.length > 0) {
        chart.data.origDatasetsData.push(_.cloneDeep(datasets[i].data));
      }
    }
  } else if (chart.data.origDatasetsData.length) {
    chart.data.origDatasetsData = [];
    for (var i in datasets) {
      if (datasets[i].data && datasets[i].data.length > 0) {
        chart.data.origDatasetsData.push(_.cloneDeep(datasets[i].data));
      }
    }
  }
  var originalDatasetsData = _.cloneDeep(chart.data.origDatasetsData);
  if (originalDatasetsData.length == 0) return;
  //var chartOptions = chart.options.scales.xAxes[0];
  // var startX = chart.data?.my_decimation.start;
  // var endX = chart.data?.my_decimation.end;
  let isZoomed = false;

  if (chart.getZoomLevel() > 1) {
    var startZoom = chart.scales.x.min;
    var endZoom = chart.scales.x.max;
    isZoomed = true;
    originalDatasetsData = chart.data.my_decimation.data;
  }

  for (let i = 0; i < originalDatasetsData.length; i++) {
    var originalData = _.cloneDeep(originalDatasetsData[i]);

    if (!originalData.length) continue;

    var firstElement = { index: 0, time: null };
    var lastElement = { index: originalData.length - 1, time: null };

    if (isZoomed) {
      for (var j = 0; j < originalData.length; j++) {
        var time = originalData[j].x;
        if (
          time >= startZoom &&
          (firstElement.time === null || time < firstElement.time)
        ) {
          firstElement.index = j;
          firstElement.time = time;
        }
        if (
          time <= endZoom &&
          (lastElement.time === null || time > lastElement.time)
        ) {
          lastElement.index = j;
          lastElement.time = time;
        }
      }
    }

    var startIndex =
      firstElement.index <= lastElement.index
        ? firstElement.index
        : lastElement.index;
    var endIndex =
      firstElement.index >= lastElement.index
        ? firstElement.index
        : lastElement.index;
    datasets[i].data = reduce(
      originalData.slice(startIndex, endIndex + 1),
      maxRenderedPointsX,
      chart.data?.my_decimation?.accuracy[i]
    );
  }
}

// returns a reduced version of the data array, averaging x and y values
function reduce(data: any, maxCount: any, accuracy: any) {
  if (data.length <= maxCount) return data;
  var blockSize = data.length / maxCount;
  var reduced = [];
  for (var i = 0; i < data.length; ) {
    var chunk = data.slice(i, (i += blockSize) + 1);
    reduced.push(average(chunk, accuracy));
  }
  return reduced;
}

function average(chunk: any, accuracy: any) {
  var x = 0;
  var y = 0;
  var xGaps = 0;
  var yGaps = 0;
  let gaps = 0;
  let rows = chunk.length;
  let qcFlag: any = [0, 0, 0, 0];
  for (var i = 0; i < chunk.length; i++) {
    if (chunk[i].y == null) {
      ++gaps;
      --rows;
      xGaps += chunk[i].x;
      yGaps += chunk[i].y;
    } else {
      qcFlag[chunk[i].qcFlag] = ++qcFlag[chunk[i].qcFlag];
      x += chunk[i].x;
      y += chunk[i].y;
    }
  }
  if (gaps > Math.floor(chunk.length / 2)) {
    return { x: Math.round(xGaps / gaps), y: null, qcFlag: 4 };
  }
  const value = (y / rows).toFixed(accuracy);
  const ts = Math.round(x / rows);

  return {
    x: ts,
    y: Number(value),
    qcFlag: qcFlag.lastIndexOf(Math.max(...qcFlag)),
    tooltip: {
      label: ts,
      value: value
    }
  };
}

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  TimeScale,
  zoomPlugin,
  reduceDataPointsPlugin
);

// @ts-ignore
Tooltip.positioners.custom = function (elements, eventPosition) {
  /** @type {Chart.Tooltip} */
  var tooltip = this;

  /* ... */

  return {
    x: eventPosition.x,
    y: eventPosition.y
  };
};

const externalTooltipHandler = (context: any) => {
  myChartJsTooltipHandler(context, (tooltip: any) => {
    const data = tooltip.dataPoints[0].raw.tooltip;
    const dataset = tooltip.dataPoints[0].dataset;
    const title = dataset.label.split('(');
    const label = `${momentTZ(data.label).format('DD MMM YYYY, HH:mm')}`;
    const body = `<div class="label">${label}</div>
    <div class="values" style="color: ${dataset.backgroundColor}">
      <div class="q">${title[0]}</div>
      <div class="a">${data.value} ${title[1].replace(')', '')}</div>
    </div>
    `;
    return body;
  });
};

const options: any = {
  responsive: true,
  maintainAspectRatio: false,
  parsing: false,
  animation: false,
  plugins: {
    legend: {
      display: false,
      position: 'top',
      align: 'center',
      labels: {
        usePointStyle: false,
        font: {
          size: 15
        },
        color: custom_palettes.gray[800]

        // generateLabels: (chart: any) => {
        //   const firstLabelText = chart.data.datasets[0].label || '';
        //   const secondLabelText =
        //     chart.data.datasets.length > 1 ? chart.data.datasets[1].label : '';
        //   return [
        //     {
        //       fontColor: custom_palettes.gray[800],
        //       datasetIndex: 0,
        //       pointStyle: 'circle',
        //       text: firstLabelText
        //     },
        //     {
        //       fontColor: custom_palettes.gray[800],
        //       datasetIndex: 1,
        //       pointStyle: 'rect',
        //       text: secondLabelText
        //     }
        //   ];
        // }
      }
    },
    tooltip: {
      enabled: false,
      position: 'custom',
      external: externalTooltipHandler
    },
    zoom: {
      pan: {
        enabled: true,
        mode: 'x'
      },
      zoom: {
        wheel: {
          enabled: true,
          speed: 0.01
        },
        pinch: {
          enabled: false
        },
        mode: 'x'
        // TODO: Below code is used to restrict the user to
        // no to zoom-in to a level where we are not able to display granular data
        // onZoomStart: ({ chart }: { chart: any }) => {
        //   console.log(chart.scales['x'].ticks.length);
        //   console.log(chart.scales['x'].ticks.length >= 2);
        //   return chart.scales['x'].ticks.length >= 2;
        // }
      }
    },
    decimation: {
      enabled: true,
      algorithm: 'lttb',
      samples: 100,
      threshold: 100
    }
  },
  elements: {
    point: {
      radius: 3
    },
    line: {
      borderWidth: 2
    }
  },
  scales: {
    x: {
      type: 'time',
      time: {
        unit: 'day'
      },
      ticks: {
        callback: function (value: any, index: any, ticks: any) {
          return moment(value).format('D MMM YYYY');
        },
        stepSize: 1
      },
      border: {
        color: custom_palettes.gray[800],
        width: 1
      },
      title: {
        display: true,
        text: `Time (UTC+8:00)`,
        color: custom_palettes.gray[800],
        padding: 5,
        font: {
          size: 16
        }
      }
    },
    y: {
      type: 'linear',
      display: true,
      position: 'left',
      grid: { display: false },
      border: {
        color: custom_palettes.blue[800],
        width: 3
      }
    }
  }
};

const data: any = {
  datasets: [
    {
      yAxisID: 'y',
      borderColor: custom_palettes.blue[800],
      backgroundColor: custom_palettes.blue[800],
      pointStyle: 'circle',
      pointBackgroundColor: custom_palettes.white.main,
      segment: {
        // borderColor: (ctx: any) => {
        //   if (ctx.p0.raw.qcFlag > 0) {
        //     return 'red';
        //   }
        //   return undefined;
        // }
        borderDash: (ctx: any) => (ctx.p0.raw.qcFlag == 0 ? [3, 3] : undefined)
      }
    }
  ]
};

// ===========================================================================
interface LineGraphProps {
  data: any[];
  xAxisLabel?: string;
  xAxisUnit?: string;
  yAxisLabel?: string;
  yAxisUnit?: string;
  style?: React.CSSProperties;
  accuracy?: any;
}

export default function LineGraph(props: LineGraphProps) {
  const [isFirst, setFirst] = useState(true);
  const startTime: any = useAppSelector(
    (state) => state.graphController.startTime
  );
  const endTime = useAppSelector((state) => state.graphController.endTime);
  const cardSet = useAppSelector((state) => state.datasetCardController.set);
  const [parameters, setParameters] = useState<string[]>(
    cardSet.map((card: any) => `${card.parameterDesc} ${card.unit}`)
  );
  // const [zoomedDomain, setZoomedDomain] = React.useState([
  //   moment(startTime).toDate(),
  //   getMaxTime()
  // ]);
  // const debounce = useDebounce(zoomedDomain, 500);
  const [chartOptions, setChartOptions] = useState(options);
  const chartRef = useRef();
  React.useEffect(() => {
    // const zData = getZoomedData(
    //   props,
    //   zoomedDomain,
    //   debounce,
    //   isFirst,
    //   setFirst
    // );
    if (chartRef && chartRef.current && props.data.length) {
      // @ts-ignore
      const chart: any = chartRef.current;
      const co = { ...chartOptions };

      if (props.data.length === 2) {
        chart.data.datasets[0].label = parameters[0];
        co.scales.y.title = {
          display: true,
          text: parameters[0],
          color: custom_palettes.gray[800],
          padding: 5,
          font: {
            size: 16
          }
        };
        chart.data.datasets[0].data = props.data[0];
        chart.data.datasets[1] = {
          label: parameters[1],
          yAxisID: 'y1',
          data: props.data[1],
          borderColor: custom_palettes.green[500],
          backgroundColor: custom_palettes.green[500],
          pointStyle: 'rect',
          pointBackgroundColor: custom_palettes.white.main,
          segment: {
            borderDash: (ctx: any) =>
              ctx.p0.raw.qcFlag == 0 ? [3, 3] : undefined
          }
        };
        co.scales.y1.title = {
          display: true,
          text: parameters[1],
          color: custom_palettes.gray[800],
          padding: 5,
          font: {
            size: 16
          }
        };
      } else {
        chart.data.datasets[0].label = parameters[0];
        co.scales.y.title = {
          display: true,
          text: parameters[0],
          color: custom_palettes.gray[800],
          padding: 5,
          font: {
            size: 16
          }
        };
        chart.data.datasets[0].data = props.data[0];

        if (chart.data?.datasets?.length == 2) {
          chart.data.datasets.pop();
        }
      }
      co.plugins.legend.display = true;
      chart.data.my_decimation = {
        start: startTime,
        end: endTime,
        accuracy: props.accuracy,
        data: props.data
      };
      setChartOptions(co);
      chart.update();
    }
  }, [props.data]);

  useEffect(() => {
    setParameters(
      cardSet.map((card: any) => `${card.parameterDesc} ${card.unit}`)
    );
    const co: any = { ...chartOptions };
    const length = Object.keys(cardSet).length;
    if (length === 2) {
      co['scales']['y1'] = {
        type: 'linear',
        display: true,
        position: 'right',
        grid: { display: false },
        border: {
          color: custom_palettes.green[500],
          width: 3
        }
      };
    } else if (length === 1) {
      delete co['scales']['y1'];
    } else {
      co.plugins.legend.display = false;
    }
    setChartOptions(co);
    // return () => {
    //   if (chartRef && chartRef.current) {
    //     // @ts-ignore
    //     chartRef.current.destroy();
    //   }
    // };
  }, [cardSet]);

  useEffect(() => {
    //const maxTime = getMaxTime();
    //setZoomedDomain([moment(startTime).toDate(), maxTime]);
    const co = { ...chartOptions };
    co.plugins.zoom.limits = {
      x: {
        min: moment(startTime).valueOf(),
        max: moment(endTime).valueOf()
      }
    };

    co.scales.x.min = moment(startTime).valueOf();
    co.scales.x.max = moment(endTime).valueOf();
    setChartOptions(co);
  }, [props.data]);

  return (
    <div
      style={{
        paddingRight: 10,
        paddingLeft: 10,
        paddingBottom: 30,
        ...props.style
      }}
    >
      <div
        style={{
          position: 'relative',
          width: '100%',
          height: 400
        }}
      >
        <Line ref={chartRef} options={chartOptions} data={data} />
      </div>
    </div>
  );

  function getMaxTime(): Date {
    const allTimes = props.data.flatMap((d) => d.map((dt: any) => dt.x));
    const maxTime = Math.max.apply(null, allTimes);

    // @ts-ignore
    return props?.data?.length ? new Date(maxTime) : moment(endTime).toDate();
  }
}
