import _ from 'lodash';
import momentTZ from 'moment-timezone';

const processBoxData = (inp: any, binSize: string = '1D') => {
  let format = 'MM/D/YYYY';

  if (binSize === '1W') {
    format = 'YYYY[W]WW';
  } else if (binSize === '1M') {
    format = 'YYYYMM';
  }

  const results = inp.data.map((datum: any, index: any) => {
    const filteredDatum = datum.slice().filter((val: any) => val.qcFlag < 3);
    const groupedList: any = _.groupBy(filteredDatum, (d) =>
      momentTZ(d.x).format(format)
    );

    return {
      labels: Object.keys(groupedList).map((l: any) =>
        momentTZ(l).format('D MMM YYYY')
      ),
      data: Object.keys(groupedList).map((d: string) =>
        groupedList[d].map((i: any) => Number(i.y.toFixed(inp.accuracy[index])))
      )
    };
  });

  return results;
};

// const processDailyAvgData = (data: any) => {
//   return processBoxData(data).map((datum: any) =>
//     datum.map((d: any) => ({
//       x: d.x,
//       y: _.mean(d.y),
//       label: `${momentTZ(d.x).format('DD MMM')}\n${_.mean(d.y).toFixed(
//         2
//       )}`
//     }))
//   );
// };

const processScatterData = (data: any) => {
  if (data && data.data.length !== 2) return [];
  const groupLists = _.map(processBoxData(data), 'data');
  const scatterData: any[] = [];

  groupLists[0].map((d: any, index: any) => {
    if (!!groupLists[1][index]) {
      const x = _.mean(groupLists[0][index]);
      const y = _.mean(groupLists[1][index]);
      scatterData.push({
        x,
        y,
        label: `x: ${x.toFixed(2)}\ny: ${y.toFixed(2)}`
      });
    }
  });

  return scatterData;
};

const processAvgData = (data: any, accuracy: any) => {
  if (data && data.length) {
    const maxPoints = 100;
    const startTime = data[0].x;
    const endTime = data[data.length - 1].x;
    const fullWindow = endTime - startTime;
    const binSize = Math.floor(fullWindow / Math.min(maxPoints, data.length));

    if (data.length > maxPoints) {
      const newFiltered: any[] = [];
      let i = 0;

      while (i < data.length) {
        let sum = 0.0,
          j = i;
        const startPivot = data[i].x;
        const endPivot = startPivot + binSize;

        while (j < data.length && data[j].x <= endPivot) {
          sum += data[j++].y;
        }

        newFiltered.push({
          x: startPivot + binSize / 2,
          y: sum / (j - i),
          tooltip: {
            label: `${momentTZ(startPivot + binSize / 2).format(
              'DD MMM YYYY, HH:mm'
            )}`,
            value: `${(sum / (j - i)).toFixed(accuracy)}`
          }
        });
        i = j;
      }

      data = newFiltered;
    }

    //TODO: check tooltip if < maxPoints

    let finalData = [];

    for (let i = 0; i < data.length; i++) {
      finalData.push({
        ...data[i],
        tooltip: {
          label: `${momentTZ(data[i].x).format('DD MMM YYYY, HH:mm')}`,
          value: `${data[i].y.toFixed(accuracy)}`
        }
      });
      if (i < data.length - 1 && data[i + 1].x - data[i].x > binSize * 3) {
        finalData.push({
          x: new Date(data[i].x + binSize).getTime(),
          y: null
        });
      }
    }

    return finalData;
  }
  return [];
};
const getZoomedData = (
  props: any,
  zoomedDomain: any,
  debounce: boolean,
  isFirst: boolean,
  setFirst: any
) => {
  const maxPoints = 100;
  const results = props.data.map((datum: any, index: any) => {
    datum.sort((d1: any, d2: any) => d1.x - d2.x);
    let filtered = datum.filter(
      (d: any) => d.x >= zoomedDomain[0] && d.x <= zoomedDomain[1]
    );

    const fullWindow = zoomedDomain[1].getTime() - zoomedDomain[0].getTime();
    const binSize = Math.floor(
      fullWindow / Math.min(maxPoints, filtered.length)
    );

    if (debounce || isFirst) {
      if (filtered.length > maxPoints) {
        const newFiltered: any[] = [];
        let i = 0;

        while (i < filtered.length) {
          let sum = 0.0,
            j = i;
          const startTime = filtered[i].x.getTime();
          const endTime = startTime + binSize;

          while (j < filtered.length && filtered[j].x.getTime() <= endTime) {
            sum += filtered[j++].y;
          }

          newFiltered.push({
            x: momentTZ(startTime + binSize / 2),
            y: sum / (j - i),
            qcFlag: filtered[i].qcFlag,
            tooltip: {
              label: `${momentTZ(new Date(startTime + binSize / 2)).format(
                'DD MMM YYYY, HH:mm'
              )}`,
              value: `${(sum / (j - i)).toFixed(props.accuracy[index])}`
            }
          });
          i = j;
        }

        filtered = newFiltered;
      }
    }

    let finalFiltered = [];

    for (let i = 0; i < filtered.length; i++) {
      finalFiltered.push(filtered[i]);
      if (
        i < filtered.length - 1 &&
        filtered[i + 1].x - filtered[i].x > binSize * 3
      ) {
        finalFiltered.push({
          x: filtered[i].x.add(binSize, 'ms'),
          y: null
        });
      }
    }

    return finalFiltered;
  });

  if (isFirst) {
    setFirst(false);
  }

  return results;
};

const generate5MinIntervals = (start: number, end: number) => {
  const res: number[] = [];

  while (start < end) {
    res.push(start);
    start = start + 5 * 60 * 1000;
  }

  return res;
};

const processDataWithGaps = (
  start: number,
  end: number,
  graphData: any,
  nextParams: any
) => {
  const pco2Index = nextParams.indexOf('calculatedCO2');
  const phIndex = nextParams.indexOf('pHInt');
  const nitrateIndex = nextParams.indexOf('NO3_scaled');

  return graphData.map((param: any, index: any) => {
    let res;

    if (
      pco2Index == index ||
      phIndex == index ||
      param.isCruiseParam ||
      nitrateIndex == index
    ) {
      res = param.data.dataTs.map((ts: any, index: any) => {
        const value =
          param.data.qcFlag[index] < 3 ? param.data.qcValue[index] : null;
        return {
          y: value,
          x: ts,
          qcFlag: param.data.qcFlag[index],
          ...(value != null && {
            tooltip: {
              label: ts,
              value: `${value.toFixed(param.accuracy)}`
            }
          })
        };
      });
    } else {
      const intervals: number[] = generate5MinIntervals(start, end);

      const graphDataObjs = graphData.map((param: any) => {
        return param.data.dataTs.reduce((obj: any, ts: any, index: any) => {
          obj[ts] = {
            dataTs: ts,
            qcFlag: param.data.qcFlag[index],
            qcValue: param.data.qcValue[index]
          };
          return obj;
        }, {});
      });

      res = intervals.map((interval) => {
        let data: any = {
          x: interval,
          y: null,
          qcFlag: 4
        };
        const val = graphDataObjs[index][interval];
        if (val) {
          const value = val.qcFlag < 3 ? val.qcValue : null;
          data = {
            y: value,
            x: val.dataTs,
            qcFlag: val.qcFlag,
            ...(value != null && {
              tooltip: {
                label: val.dataTs,
                value: `${value.toFixed(param.accuracy)}`
              }
            })
          };
        }
        return data;
      });
    }
    return { ...param, data: res };
  });
};

export {
  getZoomedData,
  processAvgData,
  processBoxData,
  processScatterData,
  generate5MinIntervals,
  processDataWithGaps
};
