import React, { useEffect } from 'react';
import TextField from '@mui/material/TextField';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import { useAppDispatch } from 'redux/hooks';
import { setStartTime, setEndTime } from 'redux/slice/graphControllerSlice';
import moment from 'moment';

// =============================================================================
export default function GraphTimeRange() {
  const dispatch = useAppDispatch();
  const [start, setStart] = React.useState(
    moment().subtract(2, 'weeks').startOf('day').unix() * 1000
  );
  const [end, setEnd] = React.useState(moment().endOf('day').unix() * 1000);

  useEffect(() => {
    dispatch(setStartTime(moment(start).startOf('day').unix() * 1000));
  }, [start]);

  useEffect(() => {
    dispatch(setEndTime(moment(end).endOf('day').unix() * 1000));
  }, [end]);

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        margin: 48,
        marginBottom: 0
      }}
    >
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <DatePicker
          renderInput={(props: any) => <TextField {...props} />}
          label='Start'
          value={moment(start).format('YYYY-MM-DD HH:mm:ss')}
          inputFormat='DD-MMM-YYYY'
          onChange={(newValue: any) => {
            setStart(newValue);
          }}
        />
        <div style={{ width: 16 }} />
        <DatePicker
          renderInput={(props: any) => <TextField {...props} />}
          label='End'
          value={moment(end).format('YYYY-MM-DD HH:mm:ss')}
          inputFormat='DD-MMM-YYYY'
          onChange={(newValue: any) => {
            setEnd(newValue);
          }}
        />
      </LocalizationProvider>
    </div>
  );
}
