import moment from 'moment';
import * as d3TimeFormat from 'd3-time-format';
import * as d3Time from 'd3-time';
import { oas3Api } from 'utils/api';
import api from 'utils/api';
import { processAvgData } from 'utils/data';
import { updatePreviewChartData } from 'redux/slice/previewDataControllerSlice';
import momentTZ from 'moment-timezone';

interface SensorDataRequest {
  sensorClass: string;
  quantityName: string;
  nodeId: number;
}

// nodeID, nodeName, sensorClass, sensorClassname, quantity, units, accuracy
export const params: { [key: string]: any[] } = {
  // 'Wind Speed': [
  //   68986846,
  //   'I2R-G',
  //   'DeltaOHM HD52.3DP147 anemometer',
  //   'wtstn',
  //   'windSpeed',
  //   'm/s',
  //   1
  // ],
  // 'Wind Direction': [
  //   68986846,
  //   'I2R-G',
  //   'DeltaOHM HD52.3DP147 anemometer',
  //   'wtstn',
  //   'windDirection',
  //   'degree',
  //   1
  // ],
  'Water Temperature': [
    315331064,
    'I2R-G',
    'sbe37',
    'sbe37',
    'temperature',
    'degree celcius',
    2
  ],
  'Air Temperature': [
    315331064,
    'I2R-G',
    'DeltaOHM HD52.3DP147 anemometer',
    'wtstn',
    'tempPt100',
    'degC',
    2
  ]
  // 'Air Pressure': [
  //   68986846,
  //   'I2R-G',
  //   'DeltaOHM HD52.3DP147 anemometer',
  //   'wtstn',
  //   'barometricPressure',
  //   'atm',
  //   1
  // ],
  // Salinity: [68986846, 'I2R-G', 'sbe37', 'cdt', 'salinity', 'PSU', 3],
  // 'Solar Irradiance': [
  //   68986846,
  //   'I2R-G',
  //   'DeltaOHM HD52.3DP147 anemometer',
  //   'wtstn',
  //   'solarRadiation',
  //   'W/m2',
  //   1
  // ]
};

const getWindDirection = (deg: number) => {
  if (deg >= 22.5 && deg < 67.5) return 'NE';
  if (deg >= 67.5 && deg < 112.5) return 'E';
  if (deg >= 112.5 && deg < 157.5) return 'SE';
  if (deg >= 157.5 && deg < 202.5) return 'S';
  if (deg >= 202.5 && deg < 247.5) return 'SW';
  if (deg >= 247.5 && deg < 292.5) return 'W';
  if (deg >= 292.5 && deg < 337.5) return 'NW';
  return 'N';
};

const fetchLatestData = async () => {
  const startTimestamp = moment().subtract(60, 'minutes').valueOf();
  const endTimestamp = moment().valueOf();

  return Promise.all(
    Object.keys(params).map((paramName: string) => {
      const param = params[paramName];
      const req: SensorDataRequest = {
        nodeId: param[0],
        sensorClass: param[2],
        quantityName: param[4]
      };

      return oas3Api.get('/sensorData2', {
        params: {
          ...req,
          startTimestamp,
          endTimestamp
        },
        accuracy: param[6]
      });
    })
  );
};

const fetchLastWeekData = async () => {
  const startTimestamp = moment().subtract(1, 'days').valueOf();
  const endTimestamp = moment().valueOf();

  return Promise.all(
    Object.keys(params)
      .filter(
        (paramName: string) =>
          paramName === 'Water Temperature' || paramName === 'Air Temperature'
      )
      .map(async (paramName: string) => {
        const param = params[paramName];
        const req: SensorDataRequest = {
          nodeId: param[0],
          sensorClass: param[2],
          quantityName: param[4]
        };
        let currStartTime = startTimestamp;
        let currEndTime = endTimestamp;
        let finalResponse: any = [];
        let iteration = 0;
        let promises = [];
        while (++iteration <= 8) {
          promises.push(
            oas3Api.get('/sensorData2', {
              params: {
                ...req,
                startTimestamp: currStartTime,
                endTimestamp: currEndTime,
                maxDataPts: 5000
              },
              accuracy: param[6]
            })
          );

          currEndTime = currStartTime;
          currStartTime = moment().subtract(iteration, 'days').valueOf();
        }

        finalResponse = Promise.all(promises).then((values) => {
          return values.map((res) => res.data.data);
        });

        return finalResponse;
      })
  );
};

const formatMillisecond = d3TimeFormat.timeFormat('.%L'),
  formatSecond = d3TimeFormat.timeFormat(':%S'),
  formatMinute = d3TimeFormat.timeFormat('%H:%M'),
  formatHour = d3TimeFormat.timeFormat('%H:%M, %d %b'),
  formatDay = d3TimeFormat.timeFormat('%d %b'),
  formatWeek = d3TimeFormat.timeFormat('%d %b'),
  formatMonth = d3TimeFormat.timeFormat('%B'),
  formatYear = d3TimeFormat.timeFormat('%Y');

function multiFormat(date: Date) {
  return (
    d3Time.timeSecond(date) < date
      ? formatMillisecond
      : d3Time.timeMinute(date) < date
      ? formatSecond
      : d3Time.timeHour(date) < date
      ? formatMinute
      : d3Time.timeDay(date) < date
      ? formatHour
      : d3Time.timeMonth(date) < date
      ? d3Time.timeWeek(date) < date
        ? formatDay
        : formatWeek
      : d3Time.timeYear(date) < date
      ? formatMonth
      : formatYear
  )(date);
}

const getDataForLandingPage = async (dispatch: Function, location: string) => {
  const waterRes = await api.post('/public/latestGraphData', {
    location,
    quantity: 'temperature'
  });

  const accuracy = 2;
  if (waterRes && waterRes.data) {
    const waterData = waterRes.data.map((obj: any) => {
      const val = Number(obj.qcValue).toFixed(accuracy);
      const ts = Number(obj.dataTs);
      return {
        y: val,
        x: ts,
        tooltip: {
          label: `${momentTZ(ts).format('DD MMM YYYY, HH:mm')}`,
          value: val
        }
      };
    });

    dispatch(
      updatePreviewChartData({
        location,
        quantity: 'temperature',
        data: waterData
      })
    );
  }

  const airRes = await api.post('/public/latestGraphData', {
    location,
    quantity: 'temppt100'
  });

  if (airRes && airRes.data) {
    const airData = airRes.data.map((obj: any) => {
      const val = Number(obj.qcValue).toFixed(accuracy);
      const ts = Number(obj.dataTs);
      return {
        y: val,
        x: ts,
        tooltip: {
          label: `${momentTZ(ts).format('DD MMM YYYY, HH:mm')}`,
          value: val
        }
      };
    });

    dispatch(
      updatePreviewChartData({
        location,
        quantity: 'temppt100',
        data: airData
      })
    );
  }
};

export {
  fetchLatestData,
  getWindDirection,
  fetchLastWeekData,
  multiFormat,
  getDataForLandingPage
};
