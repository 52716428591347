import Button from '@mui/material/Button';
import { AxiosRequestConfig } from 'axios';
import api from 'utils/api';
import { useAppSelector, useAppDispatch } from 'redux/hooks';
import {
  setGraphData,
  setIsLoading,
  setPrevEndTime,
  setPrevStartTime
} from 'redux/slice/graphControllerSlice';
import AddchartRoundedIcon from '@mui/icons-material/AddchartRounded';
import moment from 'moment';

// =============================================================================
interface GetDataButtonProps {
  disabled?: boolean;
}

type RequestConfig = AxiosRequestConfig & {
  accuracy: any;
};

// =============================================================================
export default function GetDataButton({
  disabled = false
}: GetDataButtonProps) {
  const dispatch = useAppDispatch();
  const cardSet = useAppSelector((state) => state.datasetCardController.set);
  const startTime = useAppSelector((state) => state.graphController.startTime);
  const graphData = useAppSelector((state) => state.graphController.graphData);
  const endTime = useAppSelector((state) => state.graphController.endTime);
  const prevStartTime = useAppSelector(
    (state) => state.graphController.prevStartTime
  );
  const prevEndTime = useAppSelector(
    (state) => state.graphController.prevEndTime
  );

  return (
    <div>
      <Button
        variant='secondary'
        color='dark'
        disabled={disabled}
        onClick={handleClick}
      >
        <AddchartRoundedIcon />
        Plot
      </Button>
    </div>
  );

  async function handleClick() {
    const _startTime = moment(startTime);
    const _endTime = moment(endTime);
    dispatch(setIsLoading(true));
    let newData: any = [];
    //const currentParams = graphData.map((data: any) => data.data[0].quantity);

    newData = await cardSet.reduce(async (tempData: any, card: any) => {
      // if (
      //   moment(prevStartTime).isSameOrBefore(_startTime) &&
      //   moment(prevEndTime).isSameOrAfter(_endTime) &&
      //   currentParams.includes(card.parameterName)
      // ) {
      //   const data: any = graphData[currentParams.indexOf(card.parameterName)];
      //   const temp = await tempData;
      //   temp.push({
      //     ...data,
      //     data: data.data.filter((d: any) =>
      //       moment(d.dataTs).isBetween(startTime, endTime)
      //     )
      //   });
      //   return temp;
      // } else {
      //   const query = await queryData(card);
      //   // @ts-ignore
      //   const temp = await tempData;
      //   temp.push(query);
      //   return temp;
      // }
      const query = await queryData(card);
      // @ts-ignore
      const temp = await tempData;
      temp.push(query);
      return temp;
    }, []);

    const nextParams = cardSet.map((card: any) => card.parameterName);

    dispatch(setGraphData({ data: newData, nextParams: nextParams }));
    dispatch(setPrevEndTime(endTime));
    dispatch(setPrevStartTime(startTime));
    dispatch(setIsLoading(false));
  }

  async function queryData(card: any) {
    const config: RequestConfig = {
      method: 'post',
      url: `${import.meta.env.VITE_API_URL}${
        card.isCruiseParam ? '/api/cruiseData' : '/api/aggData'
      }`,
      data: {
        quantity: card.parameterName,
        before: endTime,
        after: startTime,
        parameterId: card.id.toString(),
        parameterDesc: card.parameterDesc.toLowerCase().replace(/ /g, '_'),
        depth: card.depth.toUpperCase().replace(/ /g, '_'),
        ...(card.isCruiseParam
          ? {
              location: card.location
            }
          : {
              nodeId: card.nodeId,
              sensorClass: card.sensorClass,
              sensorId: card.sensorId
            })
      },
      accuracy: card.accuracy
    };
    const response: any = await api(config);
    // @ts-ignore
    return {
      data: response.data,
      accuracy: response.config.accuracy,
      isCruiseParam: card.isCruiseParam
    };
  }
}
