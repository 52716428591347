export const highlight = {
  title: 'Highlights'
};

export const CONSTANTS: any = {
  NODE_ID_LOCATION_MAP: {
    315331064: 'St. John’s Island',
    415331064: 'Raffles Lighthouse',
    515331064: 'Pulau Ubin',
    615331064: 'Hantu',
    715331064: 'Kusu',
    815331064: 'West Ubin'
  }
};
