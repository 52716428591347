import api from 'utils/api';
import Cookies from 'universal-cookie';
import jwt_decode from 'jwt-decode';

export const getAppVersion = () => {
  const now = new Date();
  const month = now.getMonth() + 1;
  const date = now.getDate();
  const dateStr = `${now.getFullYear()}${month < 10 ? '0' + month : month}${
    date < 10 ? '0' + date : date
  }`;
  const BUILD_NUM = import.meta.env.VITE_BUILD_NUM || '';

  return BUILD_NUM ? ` v2.0-(${dateStr}-${BUILD_NUM})` : '';
};

const getOrCreateTooltip = (chart: any) => {
  let tooltipEl = chart.canvas.parentNode.querySelector('div');

  if (!tooltipEl) {
    tooltipEl = document.createElement('div');
    tooltipEl.id = 'chartjs-tooltip';

    chart.canvas.parentNode.appendChild(tooltipEl);
  }

  return tooltipEl;
};

export const myChartJsTooltipHandler = (context: any, htmlGenerator: any) => {
  // Tooltip Element
  const { chart, tooltip } = context;
  const tooltipEl = getOrCreateTooltip(chart);

  const yAlign = tooltip.yAlign;
  const xAlign = tooltip.xAlign;

  // Hide if no tooltip
  if (tooltip.opacity === 0) {
    tooltipEl.style.opacity = 0;
    return;
  }

  // Set caret Position
  tooltipEl.classList.remove('top', 'bottom', 'center', 'left', 'right');
  // if (tooltipModel.yAlign || tooltipModel.xAlign) {
  tooltipEl.classList.add(yAlign);
  tooltipEl.classList.add(xAlign === 'center' ? 'left' : xAlign);

  // Set Text
  const body = htmlGenerator(tooltip);
  tooltipEl.innerHTML = body;

  // Tooltip height and width
  const { height, width } = tooltipEl.getBoundingClientRect();
  const { offsetLeft: positionX, offsetTop: positionY } = chart.canvas;
  // Carets
  const caretY = tooltip.caretY;
  const caretX = tooltip.caretX;

  // Final coordinates
  let top = positionY + caretY - height;
  let left = positionX + caretX - width / 2;
  let space = 8; // The caret plus one pixle for some space, you can increase it.

  // yAlign could be: `top`, `bottom`, `center`
  if (yAlign === 'top') {
    top += height + space;
  } else if (yAlign === 'center') {
    top += height / 2;
  } else if (yAlign === 'bottom') {
    top -= space;
  }

  // xAlign could be: `left`, `center`, `right`
  if (xAlign === 'left') {
    left += width / 2 - space / 2;
    if (yAlign === 'center') {
      left += space * 2;
    }
  } else if (xAlign === 'right') {
    left -= width / 2;
    if (yAlign === 'center') {
      left -= space;
    } else {
      left += space;
    }
  } else if (xAlign === 'center') {
    left += width / 2 + space * 2;
  }

  // Display, position, and set styles for font
  tooltipEl.style.opacity = 1;
  tooltipEl.style.left = left + 'px';
  tooltipEl.style.top = top + 'px';

  // // Auto position tooltip, based on dimensions and boundary
  // const chartRect = chart.canvas.getBoundingClientRect();
  // const tooltipRect = tooltipEl.getBoundingClientRect();
  // if (tooltipRect.x + tooltipRect.width > chartRect.width) {
  //   console.log('Out of bounds');
  // }
};

export const fetchUserParams = async () => {
  const cookies = new Cookies();
  const tokens = cookies.get('tokens');

  const unitMap: any = {
    'Air Pressure': 'mbar',
    CDOM: 'ppb',
    Chlorophyll: 'RFU',
    Nitrate: 'μmol/L',
    'Dissolved Oxygen': 'μmol/L',
    'Crude Oil': 'ppb',
    pH: 'pH',
    Phycoerythrin: 'μg/L',
    'Refined Fuels': 'ppm',
    'Relative Humidity': '%',
    Salinity: 'PSU',
    'Solar Irradiance': 'W/m²',
    'Water Temperature': 'degC',
    'Air Temperature': 'degC',
    Turbidity: 'NTU',
    'Wind Speed': 'm/s',
    pCO2: 'ppm',
    'Secchi depth': 'm',
    'Total alkalinity': 'µeq/kg',
    TSS: 'mg/L',
    DIC: 'µmol/kg',
    NOx: 'µmol/l',
    Phosphate: 'µmol/l',
    Silicate: 'µmol/l',
    Pb: 'pmol/kg',
    Zn: 'nmol/kg',
    Cu: 'nmol/kg',
    Cd: 'nmol/kg',
    'Current Velocity': 'cm/s',
    'Current Direction': '°'
  };

  if (tokens) {
    const decoded: any = jwt_decode(tokens.accessToken);
    const params = await api.get(`/api/accesibleParameters/${decoded.userId}`);
    return params.data.map((param: any) => ({
      ...param,
      unit: unitMap[param.parameterDesc]
    }));
  }
  return {};
};

export const getQuantity = (quantity: any): string => {
  return quantity.toLowerCase().replace(/\s/g, '-');
};

export const truncateMarkdown = (markdown: any, maxLength: any) => {
  if (markdown.length <= maxLength) return markdown;

  // Find the last space within the maxLength
  const truncated = markdown.slice(0, maxLength);
  const lastSpaceIndex = truncated.lastIndexOf(' ');

  // Cut off at the last space and add ellipsis
  return `${truncated.slice(0, lastSpaceIndex)}...`;
};
